<template>
    <div>
        <!-- 移动端样式 -->
        <!-- <mu-button small flat> -->
        <div style="padding:15px;box-sizing: border-box;" @click="back">
            <span>{{ $t('index.back') }}</span>
        </div>
        <div style="margin-top:0px;text-align: center;">
            <h3 class="product-header__title app-header__title">
                <font style="vertical-align: inherit;overflow-wrap: break-word;">
                    <font style="vertical-align: inherit">{{
                        appInfo.ipa_name
                    }}</font>
                </font>
                <!-- <span class="badge badge--product-title">
                    <font style="vertical-align: inherit">
                        <font style="vertical-align: inherit">4+</font>
                    </font>
                </span> -->
            </h3>
        </div>
        <div style="text-align:center;font-size:13px;">
            <font style="vertical-align: inherit;">
                <font style="vertical-align: inherit;color:#68C6B7;">{{ $t('index.dowloadTips') }}</font>
            </font>
        </div>
        <!-- <div style="width:65%;margin:0 auto;transform:scale(1.2)">
            <mu-stepper :activeStep="1" :linear="false">
                <mu-step>
                    <mu-step-label> 
                        {{ $t('index.downloadApp') }}
                    </mu-step-label>
                </mu-step>
                <mu-step>`
                    <mu-step-label class="demo-step-label-warning">
                        {{ $t('index.welfare') }}
                    </mu-step-label>
                </mu-step>

            </mu-stepper>
        </div> -->
        <div style="margin: 10px auto;max-width:70%;display: flex;justify-content: center;">
            <img src="../assets/step.png" alt="" srcset="" style="max-width:100%;height:auto;">
        </div>
        <div style="margin: 10px auto;max-width:88%;display: flex;justify-content: center;">
            <img src="../assets/hongbao.png" alt="" srcset="" style="max-width:100%;height:auto;">
        </div>
        <div style="margin: 10px auto;max-width:90%;display: flex;justify-content: center;position: relative;"
            @click="jumpUrl(hongbaoAd.url)"
            v-if="hongbaoAd.status === 'show' && hongbaoAdShow && whiteList.indexOf(appInfo.user_id) === -1">
            <img :src="hongbaoAd.image.includes('https://') ? hongbaoAd.image : (hongbaoAd.url + hongbaoAd.image)"
                alt="" srcset="" style="max-width:100%;height:auto;">
            <div class="topright" @click.stop="hongbaoAdShow = false">x</div>
        </div>
        <div style="margin: 10px auto;max-width:88%;display: flex;justify-content: center;" @click="Receive">
            <img src="../assets/btn.png" alt="" srcset="" style="max-width:100%;height:auto;">
        </div>
        <!-- <div style="margin:10px auto;width: 87%;">
            <mu-button full-width color="primary" @click="Receive"> {{ $t('index.Receive') }}</mu-button>
        </div> -->
    </div>
</template>

<script>
export default {
    name: "hongbao",
    props: {
        appInfo: {
            type: Object,
        },
        hongbaoAd: {
            type: Object,
        },
        jumpObject: {
            type: Object
        },
        whiteList: {
            type: String
        }
    },
    mounted() {
        // var isWeixin = this.is_weixin();
        // var cssText = "#weixin-tip{position: fixed; left:0; top:0; background: rgb(0,0,0); width: 100%; height:100%; z-index: 10000;opacity:.7;} #weixin-tip p{text-align: center; margin-top: 10%; padding:0 5%;}";
        // if (isWeixin) {
        //     this.loadHtml();
        //     this.loadStyleText(cssText);
        // }

        // window.addEventListener('resize', () => {
        //     if (window.innerWidth <= 768) {
        //         this.pcFlag = false;
        //     } else {
        //         this.pcFlag = true;
        //     }
        // })
        // this.$i18n.locale = this.language === "eng" ? 'en' : 'zh';
    },
    data() {
        return {
            pcFlag: false,
            language: 'zh',
            hongbaoAdShow: true,
        }
    },
    methods: {
        jumpUrl(url) {
            if (url && url.includes('http')) {
                window.open(url)
            }
        },
        Receive() {
            if (this.jumpObject.value && this.jumpObject.value.includes("http")) {
                window.open(this.jumpObject.value)
                // window.location = this.jumpObject.value
            }
        },
        back() {
            this.$emit('back')
        },
        is_weixin() {
            var ua = navigator.userAgent.toLowerCase();
            if (ua.match(/MicroMessenger/i) == "micromessenger") {
                return true;
            } else {
                return false;
            }
        },
        loadHtml() {
            var div = document.createElement('div');
            div.id = 'weixin-tip';
            div.innerHTML = '<p><img src="./point.png" style="width: 100%;" /></p>';			//遮罩图片
            document.body.appendChild(div);
        },
        loadStyleText(cssText) {
            var style = document.createElement('style');
            style.rel = 'stylesheet';
            style.type = 'text/css';
            try {
                style.appendChild(document.createTextNode(cssText));
            } catch (e) {
                style.styleSheet.cssText = cssText; //ie9以下
            }
            var head = document.getElementsByTagName("head")[0]; //head标签之间加上style样式
            head.appendChild(style);
        },
    }
}
</script>

<style  scoped>
.step {
    box-sizing: border-box;
    font-size: 13px;
    font-weight: 700;
    text-align: center;
    width: 30px;
    height: 30px;
    line-height: 30px;
    border: 1px solid #3882F2;
    border-radius: 50%;
}
</style>